import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"

import Moment from 'react-moment';
import 'moment/locale/de';

import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing} from "../constants"

import useToggle from "../components/useToggle"

const DateBox = ({datum, einlass, pause, adresse, mapsLink}) => {
  const [dateOpen, toggleDate] = useToggle();

  const test = datum

  return (
    <div css={css`
            width: 190px;
            margin: 0 0 ${spacing.small} 0;
            padding: ${spacing.small} 0 0 0;
            background-color: ${color.light};
            color: ${color.primary};
            border-bottom: ${spacing.xs} ${color.secondary} solid;

            &:hover {
              background-color: #fafafa;
            }
          `}
          >
            <div css={css`cursor: pointer;`} onClick={toggleDate}>
              <h4><Moment locale="de" format="dddd">{datum}</Moment></h4>
              <h4 
                css={css`
                margin-top: ${spacing.xs};
                padding-bottom: ${spacing.xs};
                font-size: 1.4rem;
              `}><Moment locale="de" format="DD.MM.YY">{datum}</Moment></h4>
              <div css={css`
                border-top: 2px ${color.secondary} solid;
                width: 4.5em;
                margin: ${spacing.xs} auto;
              `}/>
              <p
                css={css`
                  color: ${color.primary};
                  text-align: center;
                  padding: 0 0 ${spacing.small} 0;
                  margin: 0;
                `}><Moment locale="de" format="H:mm">{datum}</Moment> Uhr</p>
            </div>
            <div css={css`
              background: #fff;
              overflow: hidden;
              max-height: ${dateOpen ? '300px' : '0'};
              transition: max-height .5s ease-in-out;
            `}>
              <p css={css`
                border-top: ${spacing.xs} #EFEFEF solid;
                padding: ${spacing.small};
                line-height: 1.5;
                margin: 0;
              `}>
                Einlass: <Moment locale="de" format="H.mm">{einlass}</Moment> Uhr<br/>
                {pause ? "Mit" : "Ohne"} Pause
              </p>
              {
              mapsLink ? (
              <a css={css`
                text-decoration: none;
              `} target='_blank' href={mapsLink}>
              <div css={css`
                background-color: ${color.primaryDark};
                color: ${color.secondary};
                padding: ${spacing.small};
                position: relative;
              `}>
                <img css={css`
                  width: ${spacing.small};
                  position: absolute;
                  right: 0;
                  margin-right: ${spacing.small};
                `} src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDEyOCAxMjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaWQ9IkV4dGVybmFsX0xpbmsiIGQ9Im0xMjQgOHYzNmMwIDIuMjExLTEuNzg5IDQtNCA0cy00LTEuNzg5LTQtNHYtMjYuMzQ0bC01Ny4xNzIgNTcuMTcyYy0uNzgxLjc4MS0xLjgwNSAxLjE3Mi0yLjgyOCAxLjE3MnMtMi4wNDctLjM5MS0yLjgyOC0xLjE3MmMtMS41NjMtMS41NjMtMS41NjMtNC4wOTQgMC01LjY1Nmw1Ny4xNzItNTcuMTcyaC0yNi4zNDRjLTIuMjExIDAtNC0xLjc4OS00LTRzMS43ODktNCA0LTRoMzZjMi4yMTEgMCA0IDEuNzg5IDQgNHptLTE2IDEwNHYtNDhjMC0yLjIxMS0xLjc4OS00LTQtNHMtNCAxLjc4OS00IDR2NDhjMCAyLjIwNy0xLjc5MyA0LTQgNGgtODBjLTIuMjA3IDAtNC0xLjc5My00LTR2LTgwYzAtMi4yMDcgMS43OTMtNCA0LTRoNDhjMi4yMTEgMCA0LTEuNzg5IDQtNHMtMS43ODktNC00LTRoLTQ4Yy02LjYxNyAwLTEyIDUuMzgzLTEyIDEydjgwYzAgNi42MTcgNS4zODMgMTIgMTIgMTJoODBjNi42MTcgMCAxMi01LjM4MyAxMi0xMnoiIGZpbGw9IiNmMjhmNWYiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiPjwvcGF0aD48L2c+PC9zdmc+" />
                <p css={css`
                  margin: 1.4em 0 0 0;
                  line-height: 1.5;
                `}>
                  {adresse}
                </p>
              </div>
              </a>
              ) : ''
              }
            </div>
          </div>
  )
}

export default DateBox
