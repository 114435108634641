import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"
import sanitizeHtml from "sanitize-html"

import useToggle from "../components/useToggle"
import Footer from "../components/footer"
import TicketsBox from "../components/ticketsBox"
import DateBox from "../components/dateBox"

import Moment from 'react-moment';
import 'moment/locale/de';

export default function Home(props) {
  // Client-side Runtime Data Fetching
  const [pageData, setpageData] = useState(false)
  
  useEffect(() => {

    var fetchUrl = props.location.origin + "/dbinterface_frontend.php?get_upcoming_concert=true"; //"https://jsonplaceholder.typicode.com/todos/1"
    // console.log("Identified current location as: " + props.location.origin)
    // console.log("Getting URL: " + fetchUrl)

    // get concerts data from .json file
    fetch(`${fetchUrl}`) // actual: https://dev11.lacreme.digital/concerts_data.json // local test/dummy: ../concerts_data_dummy.json
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // console.log("fetched data: " + resultData.value)
        
        //resultData = {"id":"110","slug":"nachstes-aktuelles-konzert","name":"Nächstes \/ aktuelles Konzert","doortime_datetime":"2021-04-24T18:00:00","start_datetime":"2021-04-24T18:15:00","end_datetime":"0000-00-00 00:00:00","event_status":"https:\/\/schema.org\/EventCancelled","location_name":"Dreispitz","street_address":"Teststr. 10","postal_code":"8280","address_region":"TG","address_country":"CH","image":"jpeg","description_short":"Test Beschreibung","offer_1":25,"offer_2":5,"performer":[{"name":"Test Interpret 1","instrument":"Instrument 1"},{"name":"Test Interpret 2","instrument":"Instrument 2"}],"html_content":"<p>Test <strong><em>Beschreibung<\/em><\/strong>.<\/p>\n","maps_link":"","pause":"1"};
        setpageData(resultData);
        
      })
  }, [])

  useEffect(() => {
    if(pageData) {
      if(!document.getElementById ('concertschema')) {
      let script = document.createElement('script');
      script.type = 'application/ld+json';
      script.id = 'concertschema';
      script.async = true;
      script.innerHTML = `
{
"@context": "https://schema.org",
"@type": "Event",
"name": "${pageData.name}",
"startDate": "${pageData.start_datetime}",
"eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
"eventStatus": "${pageData.event_status}",
"location": {
  "@type": "Place",
  "name": "${pageData.location_name}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${pageData.street_address}",
    "postalCode": "${pageData.postal_code}",
    "addressRegion": "${pageData.address_region}",
    "addressCountry": "${pageData.address_country}"
  }
},
"image": [
  "https://gmlkreuzlingen.ch/admin/images/${pageData.slug}.${pageData.image}"
],
"description": "${pageData.description_short}",
"offers": {
  "@type": "Offer",
  "url": "https://gml-kreuzlingen.ch/",
  "price": "${pageData.offer_1}",
  "priceCurrency": "CHF",
  "availability": "https://schema.org/InStock",
  "validFrom": "2021-01-01T12:00"
},
${pageData.performer ? (
  '"performer": ['+
  pageData.performer.map((person) => person != null ? '{"@type": "Person", "name":"'+person.name+'"}' : '') 
  +"],") : ''}
"organizer": {
  "@type": "Organization",
  "name": "GML Kreuzlingen",
  "url": "https://gml-kreuzlingen.ch"
}
}
        `;
        // let s = document.getElementsByTagName('script')[0];
        // s.parentNode.insertBefore(script, s);


        document.head.appendChild(script);
      }
      }
  }, [pageData])

  /* sanitize-html default options */
  const options = {
    allowedTags: [ 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'abbr', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe' ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: [ 'href', 'name', 'target' ],
      p: [ 'class' ],
      // We don't currently allow img itself by default, but this
      // would make sense if we did. You could add srcset here,
      // and if you do the URL is checked for safety
      img: [ 'src' ]
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
    // URL schemes we permit
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto' ],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  }
  
  let tickets = pageData ? `
  <p>Regulär:</p><p><strong>${pageData.offer_1} CHF</strong></p><p><br></p>
  <p>Schüler/innen &amp; Studierende:</p><p><strong>${pageData.offer_1} CHF</strong></p>
  ` : "";

  let interpreten = "";
  if(pageData) {
    console.log("PAGE DATA INTERPRETEN CALL: " + JSON.stringify(pageData.performer))
    pageData.performer.map((person, i) => {
      if(person != null) {
        interpreten += (i == 0 ? '' : ', ') + person.name + ", " + person.instrument;
      }
    });
  } 
  console.log("INTERPRETEN: " + interpreten);
  
  //pageData ? pageData.performer.map((person, i) => {(i == 0 ? '' : ', ') + person.name + ", " + person.instrument}) : "";

  return (
    <Layout>
      <aside css={css`
        margin-left: ${spacing.medium};
        display: none;
        @media (min-width: ${screen.tablet}) {
          display: block;
        }
        @keyframes slideInTop {
          0% {
            opacity: 0;
            transform: translateY(50px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        } 

        @keyframes slideInTop2 {
          0% {
            opacity: 0;
            transform: translateY(50px);
          }
          30% {
            opacity: 0;
            transform: translateY(50px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        } 

        & > div:first-of-type {
          animation: 1.3s ease-out 0s 1 slideInTop2;
        }
        & > div:nth-of-type(2) {
          animation: 2s ease-out 0s 1 slideInTop2;
        }

      `}>
          
          <DateBox datum={pageData.start_datetime} einlass={pageData.doortime_datetime} adresse={pageData.location_name} mapsLink={pageData.maps_link} pause={pageData.pause} />
          <TicketsBox text={sanitizeHtml(tickets, options)}/>

          
      </aside>
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;

        padding: ${spacing.xs};
        margin: 0;

        animation: 1s ease-out 0s 1 slideInTop;
        
        @media (min-width: ${screen.xsPhone}) {
          padding: ${spacing.medium};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          margin: 0 ${spacing.medium} 0 ${spacing.small};;
        }
        @media (max-width: ${screen.tablet}) {
          h1 {
            font-size: 28px;
          }
        }
      `}>
        <h1>{pageData.name}</h1>
        <p>
        <Moment locale="de" format="dddd, D. MMMM YYYY, H:mm">{pageData.start_datetime}</Moment> Uhr<br/>
        {pageData.location_name}
        </p>
        <p>
          <b>{interpreten}</b>
        </p>
        <img src={`/images/${pageData.slug}.${pageData.image}`}
          css={css`
            width: 100% !important;
            /* float:left; */
            margin: 0 ${spacing.medium} ${spacing.xs} 0;
            display: block;

            @media (min-width: ${screen.xsPhone}) {
              width: 45%;
            }
          `} />
        <div css={css`
          a {
            text-decoration: none; color: ${color.secondaryDark};
          }
        `}dangerouslySetInnerHTML={{ __html: sanitizeHtml(pageData.html_content, options) }} ></div>
        <Footer marginTop={true}/>
      </main>
    </Layout>
  )
}
